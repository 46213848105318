/* eslint-disable @next/next/no-img-element */
import { FC } from 'react'
import styles from './FeaturedReviews.module.scss'
import { FeaturedReviewsProps } from './types'
import { ArticleCard, BasicCard, TweetCard, VideoCard } from 'components/WallOfLove'
import { elon, hamilton, huberman, whyteQuote, zuck } from 'components/WallOfLove/wallOfLoveItems'
import { QuoteCard } from 'components/WallOfLove/QuoteCard'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import useIsMobile from 'hooks/useIsMobile'
import { Button } from 'components/Phantom/Button'

export const FeaturedReviews: FC<FeaturedReviewsProps> = (props) => {
	const isMobile = useIsMobile(1023)
	return (
		<section className={styles.container}>
			<h2 className={styles.heading}>
				{props.title ?? (
					<>
						Thousands of trusted reviews
						<br />
						<span> from extraordinary people like you</span>
					</>
				)}
			</h2>
			<div className={styles.wrapper}>
				<ResponsiveMasonry columnsCountBreakPoints={{ 320: 2, 600: 3, 1024: 4 }}>
					<Masonry gutter={isMobile ? '15px' : '30px'}>
						<div className={styles.masonry_item}>
							<TweetCard
								{...zuck}
								id={'featured-reviews-zuck'}
							/>
						</div>
						<div className={styles.masonry_item}>
							<VideoCard
								{...huberman}
								video={'https://d115sb6i1ixllw.cloudfront.net/assets/hubermannoaudio.mp4'}
								autoplay={true}
								id={'featured-reviews-huberman'}
							/>
						</div>
						<div className={styles.masonry_item}>
							<QuoteCard
								{...whyteQuote}
								className={styles.quote_card}
							/>
						</div>
						<div className={styles.masonry_item}>
							<BasicCard
								title="Forbes Magazine"
								subtitle="The Pod keeps track of your body temperature, the room temperature, and even the weather to adjust the mattress temp accordingly. The results are impressive."
								imageSrc="https://d115sb6i1ixllw.cloudfront.net/assets/svgs/forbes.svg"
							/>
						</div>
						<div className={styles.masonry_item}>
							<VideoCard
								name={'Janet Gunn'}
								thumbnail={'https://eightsleep.imgix.net/0d589a2a7885703785f8dcf3f45f6ac7.png?v=1691609380'}
								video={'https://d115sb6i1ixllw.cloudfront.net/assets/bdd475a0b31e4b4da10522cb10ab5594_noaudio.mp4'}
								title={'Lifestyle & nutrition blogger'}
								autoplay={true}
								id={'featured-reviews-gunn'}
							/>
						</div>
						<div className={styles.masonry_item}>
							<BasicCard
								title="Financial Times"
								subtitle="Regulating your overnight temperature generally means sticking a leg out...Eight Sleep's system is much smarter."
								imageSrc="https://d115sb6i1ixllw.cloudfront.net/assets/svgs/financial_times.svg"
							/>
						</div>
						<div className={styles.masonry_item}>
							<TweetCard
								{...elon}
								id={'featured-reviews-elon'}
							/>
						</div>
						<div className={styles.masonry_item}>
							<ArticleCard
								{...hamilton}
								disableLink
								id={'featured-reviews-hamilton'}
							/>
						</div>
					</Masonry>
				</ResponsiveMasonry>
			</div>
			{props.cta && (
				<div className={styles.cta_wrapper}>
					<Button.Dark
						id={'featured-reviews-cta'}
						href={'/wall-of-love'}
					>
						Read reviews
					</Button.Dark>
				</div>
			)}
		</section>
	)
}
